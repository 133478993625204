import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabValue: 0,
    selectedButton: 0
};

export const tabValueSlice = createSlice({
    name: 'tabValue',
    initialState,
    reducers: {
        setTabValues: (state, action) => {
            state.tabValue = action.payload;
        },
        setSelectedButtonValues: (state, action) => {
            state.selectedButton = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setTabValues, setSelectedButtonValues } = tabValueSlice.actions;

export default tabValueSlice.reducer;
