import axios from 'axios';
export const BASE_URL = 'https://dezireapi.agsapplications.com'; //production server
//export const BASE_URL = 'https://deziretestbe.agsapplications.com'; //test server
//export const BASE_URL = 'http://192.168.1.14:5053'; //test1 server

export const axiosOpen = axios.create({
    baseURL: BASE_URL
});

export const axiosAuth = axios.create({
    baseURL: BASE_URL,
    headers: { ContentType: 'application/json' },
    withCredentials: true
});
